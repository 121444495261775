import React from "react";
import { render } from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faInstagram  } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons'; // Optional, if you want to add a PayPal icon

import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import About from "./About";
import SinglePage from "./SinglePage";
import CardContainer from './CardContainer';
import List from "./List";
import CarouselComponent from "./CarouselComponent"; // Import the CarouselComponent
import "./style.css";
import "./bootstrap.css";

function App() {
  const location = useLocation();
  
  return (
    <div className="app-container">
      <Navbar className="custom-navbar" expand="lg" collapseOnSelect>
        <Navbar.Brand as={Link} to="/">
          <img src="/fsf.png" alt="Logo" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">
              Inicio
            </Nav.Link>
            <NavDropdown title="Actividades" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/article/memoria-2020-fsf">
                Memoria 2020
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/article/actos-marzo-ua-alicante">
              Programadas
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/cardcontainer/">
              Colaboradores
            </Nav.Link>
            <Nav.Link as={Link} to="/article/convenio/">
              Convenios
            </Nav.Link>
            <Nav.Link as={Link} to="/article/modx/">
              Instituciones
            </Nav.Link>
            <NavDropdown title="Programas" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/article/programas-formacion-familiar">
              Programas de Formación Familiar
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/article/programa-sensibilizacion">
              Programa de Sensibilización
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/article/programa-hominum">
              Programa Hominum
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/article/programa-comunicar-ideas">
              Programa Comunicar Ideas
            </NavDropdown.Item>
            <NavDropdown.Divider /> 
            <NavDropdown.Item as={Link} to="/article/proyecto-aula-de-familia">
              Proyecto Aula de Familia
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/article/proyecto-mediacion-familiar">
              Proyecto de Mediación Familiar
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/article/solidaridad">
              Solidaridad
            </NavDropdown.Item>
          </NavDropdown>

          </Nav>
          <Nav>
          <Nav.Link as={Link} to="/article/contacto/">
            <FontAwesomeIcon icon={faEnvelope} />
          </Nav.Link>
            <Nav.Link href="https://www.paypal.com/donate/?hosted_button_id=9E9ZJPQ34AW96" target="_blank" className="paypal-link">
              <FontAwesomeIcon icon={faPaypal} />
            </Nav.Link>
            <Nav.Link href="https://www.facebook.com/fsfcv" target="_blank" className="social-link">
              <FontAwesomeIcon icon={faFacebook} />
            </Nav.Link>
            <Nav.Link href="https://www.youtube.com/channel/UCyc-Tl5uwhfGlfRCRr39cIw" target="_blank" className="social-link">
              <FontAwesomeIcon icon={faYoutube} />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/fsfcomunidadvalenciana/" target="_blank" className="social-link">
              <FontAwesomeIcon icon={faInstagram} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="container">
        <br />
        {location.pathname === '/' && <CarouselComponent />} {/* Conditionally render CarouselComponent */}
        <div className="col-12 col-md-12 p-3 p-md-5">
          <div className="row">
            <Route path="/" component={List} exact />
            <Route path="/article/:name" component={SinglePage} />
            <Route path="/about/" component={About} />
            <Route path="/cardcontainer/" component={CardContainer} />
          </div>
        </div>
      </div>
    </div>
  );
}

render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
