import React from "react";
import { Carousel } from "react-bootstrap";
import './CarouselComponent.css'; // Import the custom CSS file

const CarouselComponent = () => {
  return (
    <Carousel className="custom-carousel carousel-fade" interval={25000}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('./assets/images/bannerimg2.jpg')}
          alt="First slide"
        />
        <Carousel.Caption>
          <p>Fundación Servicios Familiares de la Comunidad Valenciana.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('./assets/images/bannerimg3.jpg')}
          alt="Second slide"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
